/*!
 * Pico.css v1.5.3 (https://picocss.com)
 * Copyright 2019-2022 - Licensed under MIT
 *
 * Slim version example
 * You can export only the modules you need
 */

// Config
// --------------------

// Enable responsive spacings for <header>, <main>, <footer>, <section>, <article>
$enable-responsive-spacings: false;

// Enable transitions
$enable-transitions: false;

// Enable overriding with !important
$enable-important: false;

// Pico Lib
// --------------------

// Config
@import "variables";

// Theming
@import "themes/default";

// Layout
@import "layout/document"; // html
@import "layout/sectioning"; // body, header, main, footer
@import "layout/container"; // .container, .container-fluid
@import "layout/section"; // section
@import "layout/grid"; // .grid
@import "layout/scroller"; // figure

// Content
@import "content/typography"; // a, headings, p, ul, blockquote, ...
@import "content/embedded"; // audio, canvas, iframe, img, svg, video
@import "content/button"; // button, a[role=button], type=button, type=submit ...
@import "content/form"; // input, select, textarea, label, fieldset, legend
@import "content/table"; // table, tr, td, ...

// Utilities
@import "utilities/accessibility"; // -ms-touch-action, aria-*
@import "utilities/reduce-motion"; // prefers-reduced-motion
